.navLogos {
  width: 2rem;
  height: 2rem;
}
footer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  
    width: 100%;
   background: #d1d6ea57;
  
    

}
.logos {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
}
.logos h2 {
  font-family: "Audiowide", sans-serif;
}
.links a {
  padding: 0.5rem;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-family: "Audiowide", sans-serif;
  
 

}
.left-column {
  float: left; /* Align the column to the left */
  width: 25%; /* Set the width of the column, adjust as needed */
  padding: 20px; /* Add padding to create space around the content */
  position: absolute;
  left: 5rem;
  margin-top: 2rem;
}
.right-column{
  float: left; /* Align the column to the left */
  width: 25%; /* Set the width of the column, adjust as needed */
  padding: 20px; /* Add padding to create space around the content */
  position: absolute;
  
  margin-top: 3.5rem;
}
.column{
  float: right; /* Align the column to the left */
  width: 25%; /* Set the width of the column, adjust as needed */
  padding: 20px; /* Add padding to create space around the content */
  position: absolute;
  right: 5rem;
  margin-top: 3.5rem;
}




.address h2 {
  font-size: 20px;
  margin-bottom: 4px; /* Set the font size for the heading */
   /* Remove default margin from the top of the heading */
}

.address p {
  font-size: 16px; /* Set the font size for the address text */
  /* Add margin to separate the heading and address */
}


.policy {
  border-top: 1px solid;
  border-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem;
  font-size: .6rem;
  width: 100%;
  margin-top: 10rem;
}

.policy a {
  padding: 0.5rem;
  color: #000000;
}
.map-container{
  display: flex;
  
}