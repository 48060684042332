@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import '@fortawesome/fontawesome-free/css/all.css';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  color: black;
  font-family: "Montserrat", sans-serif;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;

}
