.navLogo {
  width: 3rem;
  height: 3rem;
}
header {
  padding: 0.5rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Audiowide", sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  color: whitesmoke;
  background-color: rgb(0, 0, 0,.3);
  border-bottom: 1px solid;
  border-color: #ACACAC;
  backdrop-filter: blur(5px);
  z-index: 10000;

}
.logo {
  display: flex;
  align-items: center;
  gap: 0.9rem;
}
.logo p {
  font-family: "Audiowide", sans-serif;
  font-size: large;
}
.link a {
  padding: 0.5rem;
  text-decoration: none;
  color: whitesmoke;
  
}
