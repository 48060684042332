.BG {
  min-height: 100vh;
  max-height: fit-content;
  width: 100%;
  background-image: url("/public/asset/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
}

.BG h1 {
  width: 50%;
  font-size: 3rem;
  line-height: 1.5;
  color: white;
}
.BG ,h2,h3{
  color: #034db3;
}
.BG h3 span h3{
  color: #ff6900;
}





