
.partner-images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-image-container {
  width: 400px; /* Adjust according to your design */
  height: 250px; /* Adjust according to your design */
  margin: 19px; /* Adjust according to your design */
}

.partner-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image maintains its aspect ratio */
}
.log{
  margin-left: 3rem;
  padding: 2rem;
  margin-top: 2rem;
  color: #034db3;
  
}
